<template>
  <gis-viewer ref="gis-viewer" />
</template>

<script>
export default {
  name: 'GisViewerModule',
  components: {
    GisViewer: () =>
      import(
        /* webpackChunkName: "gis-viewer" */ '../components/Modules/GisViewer/GisViewer'
      ),
  },
};
</script>

<style lang="scss" scoped></style>
