<template>
  <div class="ant-gis-container d-flex flex-column full-height flex">
    <portal v-if="!antViewerToolbar" to="ant-toolbar">
      <div></div>
    </portal>
    <portal :order="1" to="ant-toolbar-left">
      <template v-if="isSidebarEnabled">
        <v-tooltip key="gis-viewer.sidebar" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              :color="showRightPanel ? 'primary' : ''"
              :ripple="false"
              icon
              small
              v-bind="attrs"
              @click="toggleRightPanel"
              v-on="on"
            >
              <v-icon> mdi-page-layout-sidebar-right</v-icon>
            </v-btn>
          </template>
          <span>Toggle viewer sidebar</span>
        </v-tooltip>
        <v-divider key="gis-viewer.divider1" class="mx-2" inset vertical />
      </template>
      <v-tooltip key="gis-viewer.viewType" bottom>
        <template #activator="{ on, attrs }">
          <v-btn-toggle :value="viewer.viewType" mandatory>
            <v-btn
              :disabled="!isView2DEnabled"
              :ripple="false"
              icon
              small
              v-bind="attrs"
              value="2d"
              @click="viewer.viewType === '3d' && toggleViewType('2d')"
              v-on="on"
            >
              <span>2D</span>
            </v-btn>
            <v-btn
              :disabled="!isView3DEnabled"
              :ripple="false"
              icon
              small
              v-bind="attrs"
              value="3d"
              @click="viewer.viewType === '2d' && toggleViewType('3d')"
              v-on="on"
            >
              <span>3D</span>
            </v-btn>
          </v-btn-toggle>
        </template>
        <span>View type 2D/3D</span>
      </v-tooltip>
      <v-divider key="gis-viewer.divider2" class="mx-2" inset vertical />
      <v-tooltip key="gis-viewer.layers" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :color="showLayers ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="toggleLayerList(!showLayers)"
            v-on="on"
          >
            <v-icon> mdi-layers</v-icon>
          </v-btn>
        </template>
        <span>Display Layers</span>
      </v-tooltip>
      <v-tooltip v-if="hasFeatureTable" key="gis-viewer.feature-table" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :color="showFeatureTable ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="toggleFeatureLayers"
            v-on="on"
          >
            <v-icon> mdi-table-eye</v-icon>
          </v-btn>
        </template>
        <span>Display Feature Table</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.ant-table" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :color="showAntTable ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="toggleAntTableDisplay"
            v-on="on"
          >
            <v-icon> mdi-table-network</v-icon>
          </v-btn>
        </template>
        <span>Display ANT table</span>
      </v-tooltip>
      <v-divider key="gis-viewer.divider3" class="mx-2" inset vertical />
      <v-tooltip key="gis-viewer.zoom-in" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="zoomIn"
            v-on="on"
          >
            <v-icon> mdi-magnify-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Zoom in</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.home" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="toHome"
            v-on="on"
          >
            <v-icon> mdi-home</v-icon>
          </v-btn>
        </template>
        <span>Go to home</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.zoom-out" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="zoomOut"
            v-on="on"
          >
            <v-icon> mdi-magnify-minus-outline</v-icon>
          </v-btn>
        </template>
        <span>Zoom out</span>
      </v-tooltip>
      <v-divider key="gis-viewer.divider4" class="mx-2" inset vertical />
      <v-tooltip key="gis-viewer.zoom-feature" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            id="zoom-features-btn"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="zoomToFeatures"
            v-on="on"
          >
            <v-icon> mdi-feature-search-outline</v-icon>
          </v-btn>
        </template>
        <span>Zoom to feature(s)</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.extent" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            id="full-extent-btn"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="fullExtent"
            v-on="on"
          >
            <v-icon> mdi-map-search-outline</v-icon>
          </v-btn>
        </template>
        <span>Full extent</span>
      </v-tooltip>
      <v-divider key="gis-viewer.divider5" class="mx-2" inset vertical />
      <v-tooltip key="gis-viewer.distance-measurement" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :color="activeMeasureTool === 'distance' ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="distanceMeasurement"
            v-on="on"
          >
            <v-icon> mdi-ruler</v-icon>
          </v-btn>
        </template>
        <span>Distance measurement tool</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.area-measurement" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :color="activeMeasureTool === 'area' ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="areaMeasurement"
            v-on="on"
          >
            <v-icon> mdi-ruler-square</v-icon>
          </v-btn>
        </template>
        <span>Area measurement tool</span>
      </v-tooltip>
      <v-tooltip key="gis-viewer.clear-measurement" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="clearMeasurement"
            v-on="on"
          >
            <v-icon> mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>Clear measurement</span>
      </v-tooltip>
      <v-divider key="gis-viewer.divider6" class="mx-2" inset vertical />
      <v-tooltip
        v-if="
          project &&
          project.generalPermissions &&
          project.generalPermissions.tasks
        "
        key="gis-viewer.sketch"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="showSketchPanel ? 'primary' : ''"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="toggleSketchPanelShow(!showSketchPanel)"
            v-on="on"
          >
            <v-icon> mdi-shape-outline</v-icon>
          </v-btn>
        </template>
        <span>Sketch Panel</span>
      </v-tooltip>
      <v-menu
        v-if="
          project &&
          project.generalPermissions &&
          project.generalPermissions.tasks
        "
        key="gis-viewer.color-picker"
        v-model="showColorPickerMenu"
        :close-on-content-click="false"
        :offset-x="true"
        style="z-index: 10"
        top
      >
        <template #activator="{ on: onMenu, attrs: menuAttrs }">
          <v-tooltip bottom>
            <template #activator="{ on: onTooltip }">
              <v-btn
                :disabled="!showSketchPanel"
                color="gainsboro"
                icon
                small
                v-bind="{ ...menuAttrs }"
                v-on="{ ...onMenu, ...onTooltip }"
              >
                <v-icon :color="pickerIconColor" dense>mdi-palette</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('modules.gisViewer.chooseSketchColor') }}</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-color-picker v-model="sketchColor" />
        </v-card>
      </v-menu>
      <v-tooltip
        v-if="
          project &&
          project.generalPermissions &&
          project.generalPermissions.tasks
        "
        key="gis-viewer.sketch-save"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :disabled="!showSketchPanel"
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="saveGraphics"
            v-on="on"
          >
            <v-icon> mdi-note-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>
      <v-tooltip
        v-if="isDrawingLayerPresent"
        key="gis-viewer.sketch-clear"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="mx-2"
            icon
            small
            v-bind="attrs"
            @click="$store.dispatch('gisViewer/clearCustomGraphics')"
            v-on="on"
          >
            <v-icon> mdi-layers-off</v-icon>
          </v-btn>
        </template>
        <span>Delete drawing</span>
      </v-tooltip>

      <slot name="ant-gis-toolbar-actions" />
    </portal>
    <portal to="ant-toolbar-right">
      <gis-search @clear="onSearchClear" @selected="onFeatureSelected" />
    </portal>

    <div class="d-flex flex-grow-1 overflow-hidden pos-rel">
      <div class="ant-glass-background ant-border-top radius-0 flex-grow-1">
        <div class="action ant-glass-background">
          <v-tooltip key="gis-viewer.basemap-gallery" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :color="showBasemapGallery ? 'primary' : ''"
                :ripple="false"
                class="ma-2"
                icon
                small
                v-bind="attrs"
                @click="toggleBasemapGallery(!showBasemapGallery)"
                v-on="on"
              >
                <v-icon> mdi-map-outline</v-icon>
              </v-btn>
            </template>
            <span>Display Map Gallery</span>
          </v-tooltip>
        </div>
        <gis-viewer-map
          v-if="projectConfigLoaded"
          :default-view-scene="defaultViewScene"
        />
      </div>
      <div v-if="isSidebarEnabled" class="ant-gis-sidebar-right">
        <panel-resizable
          v-if="showRightPanel"
          :default-width="400"
          :min-width="300"
          class="ant-glass-background ant-border-left full-height"
          side="right"
          @collapse="onRightPanelCollapse"
        >
          <div class="full-height pa-2">
            <slot name="sidebar-top" />
            <slot name="sidebar-bottom" />
          </div>
        </panel-resizable>
      </div>
      <div class="ant-gis-bottom-panel">
        <transition mode="out-in" name="slide-fade-down">
          <panel-resizable
            v-if="mapLoaded"
            v-show="showBottomPanel"
            :default-height="400"
            :min-height="300"
            class="ant-glass-background ant-border-top"
            @collapse="onBottomPanelCollapse"
          >
            <ant-tables
              v-show="showAntTable"
              :is-on-view="showBottomPanel && showAntTable"
            />
            <gis-feature-table v-show="showFeatureTable" />
          </panel-resizable>
        </transition>
      </div>
    </div>
    <v-menu
      v-if="
        project &&
        project.generalPermissions &&
        project.generalPermissions.tasks
      "
      key="map-action"
      v-model="actionsDialog"
      :position-x="positionX"
      :position-y="currentPointOptions?.screen?.y + 44"
      absolute
      max-width="300px"
      transition="scale-transition"
      @click:outside="closeActionsDialog"
      @keydown.esc="closeActionsDialog"
    >
      <v-list>
        <v-list-item @click.stop="onRfiCreate">
          <v-list-item-title
            >{{ $t('system.tasks.createRFI') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="!showSketchPanel" @click.stop="saveGraphics">
          <v-list-item-title
            >{{ $t('system.tasks.createDrawing') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <rfi-dialog
      key="gis-rfi-dialog"
      :is-shown="showRfiDialog"
      :metadata="sketchData"
      :options="currentPointOptions"
      :project="project"
      @changeRfi="onRfiCreated"
      @closeDialog="rfiDialogClose"
    />
  </div>
</template>

<script>
import { GISVIEWER } from '@/modules/modules';
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import { GIS_VIEWER_WIDGETS } from '@/components/Modules/GisViewer';

export default {
  name: 'GisViewer',
  components: {
    Portal,
    AntTables: () => import('@/components/AntTables'),
    PanelResizable: () => import('@/components/Project/PanelResizable'),
    GisViewerMap: () =>
      import(
        /* webpackChunkName: "gis-viewer" */ '@/components/Modules/GisViewer/GisViewerMap'
      ),
    GisFeatureTable: () =>
      import(
        /* webpackChunkName: "gis-viewer" */ '@/components/Modules/GisViewer/GisFeatureTable'
      ),
    GisSearch: () =>
      import(
        /* webpackChunkName: "gis-viewer" */ '@/components/Modules/GisViewer/GisSearch'
      ),
    RfiDialog: () =>
      import(
        /* webpackChunkName: "gis-viewer" */ '@/components/Tasks/RFI/RfiDialog.vue'
      ),
  },
  data: () => {
    return {
      antViewerToolbar: true,

      showLayers: true,
      showAntTable: false,
      showFeatureTable: false,
      showRightPanel: false,
      showColorPickerMenu: false,
      activeMeasureTool: '',
      sketchColor: { r: 0, g: 0, b: 0 },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'tasksRfi',
      'selectedSbsObject',
      'sbsSidebarToggle',
      'sbsSidebarWidth',
      'task',
    ]),
    ...mapGetters('gisViewer', {
      data: 'data',
      viewer: 'viewer',
      showSketchPanel: 'showSketchPanel',
      showBasemapGallery: 'showBasemapGallery',
      mapActionsDialog: 'mapActionsDialog',
      currentPointOptions: 'currentPointOptions',
      showRfiDialog: 'showRfiDialog',
      sketchData: 'sketchData',
      hasFeatureTable: 'hasFeatureTable',
    }),
    actionsDialog: {
      get() {
        return this.mapActionsDialog;
      },
      set() {
        this.$store.commit('gisViewer/toggle_map_actions_dialog', false);
      },
    },
    mapLoaded() {
      return !!this.viewer?.viewActive;
    },
    projectConfigLoaded() {
      return !!this.data;
    },
    showBottomPanel() {
      return this.showAntTable || this.showFeatureTable;
    },
    isSidebarEnabled() {
      return this.$slots['sidebar-top'] || this.$slots['sidebar-bottom'];
    },
    positionX() {
      return (
        this.currentPointOptions?.screen?.x +
        (this.sbsSidebarToggle ? this.sbsSidebarWidth : 0)
      );
    },
    pickerIconColor() {
      const { r, g, b } = this.sketchColor;
      return `rgba(${r}, ${g}, ${b}, 1)`;
    },
    isDrawingLayerPresent() {
      return this.viewer?.updated && !!this.viewer?.graphicsLayers?.length;
    },
    isView2DEnabled() {
      return this.data?.properties?.view_2d;
    },
    isView3DEnabled() {
      return this.data?.properties?.view_3d;
    },
    defaultViewScene() {
      return !this.isView2DEnabled && this.isView3DEnabled ? '3d' : '2d';
    },
  },
  watch: {
    task(v) {
      if (v) {
        this.$store.commit('gisViewer/toggle_show_rfi_dialog', true);
      }
    },
    sketchColor(v) {
      this.setSketchLines({ rgbColor: v });
    },
  },
  mounted() {
    this.$store.dispatch('gisViewer/fetchData', {
      projectId: this.project.id,
      moduleId:
        this.project.modules.find((module) => module.route === GISVIEWER)?.id ??
        null,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  destroyed() {
    this.$store.commit('gisViewer/clear_data');
  },
  methods: {
    onRightPanelCollapse() {
      this.showRightPanel = false;
    },
    onBottomPanelCollapse() {
      this.showFeatureTable = false;
      this.showAntTable = false;
    },
    toggleViewType(type = '2d') {
      this.$store.dispatch('gisViewer/switchToScene', type).then(() => {
        this.$store.dispatch('gisViewer/loadRfiLayer', this.tasksRfi);
      });
      this.removeAllGraphics();
    },
    toggleLayerList(state) {
      this.showLayers = state;
      this.viewer.widgets.layerList.visible = this.showLayers;
    },
    toggleBasemapGallery(state) {
      this.$store.commit('gisViewer/toggle_show_basemap_gallery', {
        value: state,
      });
      this.viewer.widgets.basemapGallery.visible = this.showBasemapGallery;
    },
    toggleAntTableDisplay() {
      this.showAntTable = !this.showAntTable;
      this.showFeatureTable = false;
      this.showRightPanel = false;
    },
    toggleFeatureLayers() {
      this.showFeatureTable = !this.showFeatureTable;
      this.showAntTable = false;
      this.showRightPanel = false;
    },
    toggleRightPanel() {
      this.showRightPanel = !this.showRightPanel;
      this.showAntTable = false;
      this.showFeatureTable = false;
    },
    removeAllGraphics() {
      const target = this.viewer.sketchLayers?.[0];
      if (target) {
        target.removeAll();
      }
    },
    toggleSketchPanelShow(state) {
      if (state) {
        this.$store.commit('gisViewer/toggle_sketch_panel', { value: state });
        // this.viewer.widgets[GIS_VIEWER_WIDGETS.SKETCH] = undefined;
        this.$store.dispatch('gisViewer/setViewerProp', {
          key: `widgets.${GIS_VIEWER_WIDGETS.SKETCH}`,
          value: undefined,
        });
        this.$store.dispatch('gisViewer/reloadWidgets');
      }
      if (!state) {
        this.$store.commit('gisViewer/toggle_sketch_panel', { value: state });
        this.$store.dispatch('gisViewer/removeWidget', {
          key: GIS_VIEWER_WIDGETS.SKETCH,
        });
        this.sketchColor = { r: 0, g: 0, b: 0 };
      }
    },
    zoomIn() {
      this.viewer.widgets.zoom.zoomIn();
    },
    zoomOut() {
      this.viewer.widgets.zoom.zoomOut();
    },
    zoomToFeatures() {
      if (!this.viewer.featureTable.layer) return;

      const featureLayer = this.viewer.featureTable.layer;
      // Create a query off of the feature layer
      const query = featureLayer.createQuery();
      // Iterate through the features and grab the feature's objectID
      query.objectIds = this.viewer.selectedFeatures.map((result) => {
        return result.feature.getAttribute(featureLayer.objectIdField);
      });
      // Make sure to return the geometry to zoom to
      query.returnGeometry = true;
      // Call queryFeatures on the feature layer and zoom to the resulting features
      featureLayer.queryFeatures(query).then((results) => {
        this.onBottomPanelCollapse();
        this.toggleLayerList(false);
        this.viewer.viewActive.goTo(results.features).catch((error) => {
          if (error.name != 'AbortError') {
            console.error(error);
          }
        });
      });
    },
    fullExtent() {
      if (!this.viewer.featureTable.layer) return;

      const featureLayer = this.viewer.featureTable.layer;

      this.onBottomPanelCollapse();
      this.toggleLayerList(false);
      // Zooms to the full extent of the feature layer
      this.viewer.viewActive.goTo(featureLayer.fullExtent).catch((error) => {
        if (error.name != 'AbortError') {
          console.error(error);
        }
      });
    },
    toHome() {
      this.viewer.widgets.home.go();
    },
    onSearchClear() {
      this.$store.dispatch('gisViewer/highlightFeatureClear');
    },
    onFeatureSelected({ item }) {
      this.$store.dispatch('gisViewer/highlightFeature', { item });
    },
    distanceMeasurement() {
      const viewType = this.viewer.viewType;
      this.viewer.widgets.measurement.activeTool =
        viewType === '2d' ? 'distance' : 'direct-line';
      this.activeMeasureTool = 'distance';
    },
    areaMeasurement() {
      this.viewer.widgets.measurement.activeTool = 'area';
      this.activeMeasureTool = 'area';
    },
    clearMeasurement() {
      this.viewer.widgets.measurement.clear();
      this.activeMeasureTool = '';
    },
    saveGraphics() {
      const items = this.viewer.sketchLayers[0]?.graphics?.items;
      const data = items.map((i) => ({
        symbol: {
          type: i.symbol.type,
          size: i.symbol.size,
          style: i.symbol.style,
          color: i.symbol.color.toRgba(),
          width: i.symbol.width,
          outline: i.symbol.outline
            ? {
                type: i.symbol.outline.type,
                color: i.symbol.outline.color.toRgba(),
                width: i.symbol.outline.width,
              }
            : undefined,
        },
        geometry: {
          spatialReference: i.geometry.spatialReference,
          type: i.geometry.type,
          paths: i.geometry.paths,
          rings: i.geometry.rings,
          latitude: i.geometry.latitude,
          longitude: i.geometry.longitude,
        },
      }));
      this.$store.commit('gisViewer/set_sketch_data', { data });
      this.$store.commit('gisViewer/toggle_show_rfi_dialog', true);
    },
    setSketchLines({ rgbColor }) {
      const widgetViewModel = this.viewer.widgets?.sketch?.viewModel;
      if (widgetViewModel && rgbColor) {
        const { r, g, b } = rgbColor;
        widgetViewModel.polylineSymbol = {
          type: 'simple-line',
          color: [r, g, b, 1],
          width: 2,
          style: 'solid',
        };
        widgetViewModel.polygonSymbol = {
          type: 'simple-fill',
          color: [r, g, b, 0.4],
          outline: {
            color: [r, g, b, 0.9],
            width: 2,
          },
        };
      }
    },
    closeActionsDialog() {
      this.$store.commit('gisViewer/toggle_map_actions_dialog', false);
    },
    onRfiCreate() {
      this.closeActionsDialog();
      this.$store.commit('gisViewer/set_sketch_data', undefined);
      this.toggleSketchPanelShow(false);
      this.$store.commit('gisViewer/toggle_show_rfi_dialog', true);
    },
    onRfiCreated() {
      this.$store.dispatch('fetchRfiTasks').then(() => {
        this.$store.dispatch('gisViewer/loadRfiLayer', this.tasksRfi);
        if (this.selectedSbsObject) {
          this.$store.dispatch('selectSBSObject', {
            sbsObject: this.selectedSbsObject,
          });
        }
      });
    },
    rfiDialogClose() {
      this.$store.commit('gisViewer/toggle_show_rfi_dialog', false);
      this.$store.commit('task_clear');
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-gis-toolbar {
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  padding: 2px 10px;
  border-bottom: solid 1px lightgray;

  .ant-toolbar-header {
    font-size: 14px;
    color: #6b6b6b;
  }
}

.ant-gis-bottom-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
}

.ant-gis-sidebar-right {
  background: linear-gradient(90deg, #f6f7f8 20%, #c9c9c9);
}

::v-deep {
  #spacer {
    width: 40px;
  }

  .action {
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
