<template>
  <div v-if="loading" class="view-loading">
    <v-progress-circular color="primary" indeterminate />
  </div>
</template>

<script>
export default {
  name: 'AntViewLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.view-loading {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.5);
  justify-content: center;
  z-index: 1000;
}
</style>
